
/*
  Links without an href have a text select pointer by default,
  but we want them to act just like links.
*/
a {
  cursor: pointer;

  &.locked {
    color: #ccc;
    cursor: not-allowed;

    &:hover {
      color: #ccc;
      background-color: transparent;
    }
  }
}
