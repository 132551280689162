
/*
  Links without an href have a text select pointer by default,
  but we want them to act just like links.
*/
a {
  cursor: pointer;
}

td {
  vertical-align: middle !important;
}

td.actions {
  // Prevent the buttons from wrapping
  min-width: 100px;
}

tr td .form-group {
  margin: 0;
}

tr.changed {
  // This time should match ROW_FADE_TIME in the JS
  animation: backgroundPulse 2s;
}

@keyframes backgroundPulse {
  30%, 70% { background-color: #d9edf7; }
}
