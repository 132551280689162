
.vue-labelled-checkbox-root {
  margin-left: 5px;
  margin-right: 5px;
  cursor: default;

  .checkbox {
    display: inline-block;
    width: 15px;
    text-align: left;
  }
}
