
// These styles are meant to mimic a Bootstrap button
.dropdown-checkboxes {
  display: inline-block;

  .btn {
    max-width: 200px;
    padding: 6px 12px;
    overflow: hidden;
    line-height: 20px;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
  }

  ul.dropdown-menu li {
    font-size: 80%;
    margin: 1px 10px;
  }

  ul.dropdown-menu label {
    font-weight: normal;
  }

  ul.dropdown-menu button {
    display: inline-block;
    margin: 6px 0 0 0;
    font-size: 90%
  }

  &.block {
    display: block;

    >.btn {
      display: block;
      width: 100%;
      max-width: none;
      padding-right: 20px;
    }

    .caret {
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }
}

