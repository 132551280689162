
.placement-dropdown {
  position: relative;

  a.open {
    float: right;
    cursor: pointer;
  }

  &.opened .placement-dropdown-dropdown {
    position: absolute;
    width: 100%;
    z-index: 100;
    box-shadow: 3px 3px 5px #ddd;
  }
}
