
.btn-group.new-single-file {
  display: flex;

  .btn {
    flex: 1;
  }
}

.sticky-top {
  position: sticky;
  top: 0px;
  z-index: 3;
  background-color: white;
  padding-top: 1em;
}
