
.loader-overlay {
  position: relative;

  .show-loading-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .loader {
    opacity: 0.7;
  }
}

