
.story-medium {
  margin-bottom: 20px;

  .media-filename {
    max-width: 250px;
    overflow: hidden;
  }
}

.media-left {
  a.image {
    position: relative;
    display: block;
  }
}
