
.sort_link {
  color: black;

  // Append FontAwesome sort icons to links
  &:after, &:after{
    padding-left: 3px;
    font-family: FontAwesome;
    font-size: 85%;
    color: #cdcdcd;
  }

  &:after {
    content: '\f160'
  }

  &.asc:after {
    color: #333;
  }

  &.desc:after {
    color: #333;
    content: '\f161'
  }
}

a.sort_link {
  cursor: pointer;

  &:hover {
    text-decoration: none;

    &:after{
      color: #333;
    }
  }
}

span.sort_link {
  cursor: not-allowed;
}
