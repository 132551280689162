
td.replacement {
  display: flex;
  justify-content: center;
  align-content: center;

  span.input-group {
    flex-grow: 4;

    &.start {
      text-align: right;
    }
  }

  span.placeholder {
    flex-grow: 1;
    margin-right: 1em;
    margin-left: 1em;
    text-align: center;
  }
}
