
.messages {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  top: 0;
  left: 0;
  width: 100%;
  // The reason for the weird pairing of margin vertical and padding for horizontal here is
  // because horizontal margin throws off the centering, and vertical padding creates a
  // transparent click-blocking layer at the top of the page.
  margin: 20px 0;
  padding: 0 20px;

  :not(:last-child) {
    display: none;
  }
}
