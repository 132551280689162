
.byline {
  .list-group-item {
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }
}
