
.vue-labelled-value-value {
  border-bottom: 1px solid #e3e3e3;
  min-height: 20px;
}

.action-icon {
  float: right;
  cursor: pointer;
}
