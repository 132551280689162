
.richtext-editor {
  .btn-toolbar {
    margin-bottom: 0.5em;
  }

  .tiptap {
    &.form-control {
      resize: vertical;
      overflow: auto;

      &.form-control-lg {
        height: 400px;
      }

      &.form-control-md {
        height: 200px;
      }

      &.form-control-sm {
        height: 100px;
      }
    }

    table {
      --selected-cell-color: #E8E8E8;
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 1px solid gainsboro;
        box-sizing: border-box;
        min-width: 1em;
        padding: 6px 8px;
        position: relative;
        vertical-align: top;

        >* {
          margin-bottom: 0;
        }
      }

      th {
        background-color: lightgray;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell {
        background: var(--selected-cell-color);
      }

      .column-resize-handle {
        background-color: black;
        bottom: -2px;
        pointer-events: none;
        position: absolute;
        right: -2px;
        top: 0;
        width: 4px;
      }
    }

    .tableWrapper {
      margin: 1.5rem 0;
      overflow-x: auto;
    }

    &.resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}
